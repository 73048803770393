<template>
  <header class="header">
    <div class="header-logo">
      <button
        v-if="isLogin"
        type="button"
        class="mobile-menu-btn"
        title="選單按鈕"
        @click="menuToggle = !menuToggle"
      ></button>
      <div class="logo">
        <RouterLink :to="isLogin ? `/user/project`: `/`">
          <img class="logo-lg" src="@/assets/images/logo.svg" alt="" />
          <img class="logo-sm" src="@/assets/images/logo-mobile.svg" alt="" />
        </RouterLink>
      </div>
    </div>
    <div class="header-nav">
      <!-- schedule -->
      <RouterLink to="/schedule" class="header-nav-link d-none-mobile"
        ><i class="icon icon-calendar mr-1" aria-hidden="true"></i>檔期行事曆</RouterLink
      >
      <!-- 登入 -->
      <RouterLink to="/login" class="header-nav-link is-dark is-icon" v-if="!isLogin">
        <i class="icon icon-user" aria-hidden="true"></i>
      </RouterLink>
      <!-- 會員 -->
      <Popper class="dropdown" v-if="isLogin">
        <div>
          <button class="header-nav-link member-link d-none-mobile">
            <div class="avatar"><i class="icon icon-user" aria-hidden="true"></i></div>
            <span class="d-none-lg-below">你好，</span>{{userName}}
            <i class="icon icon-arrow-down ml-1" aria-hidden="true"></i>
          </button>
          <button class="header-nav-link is-dark is-icon d-none-pc"
            ><i class="icon icon-user" aria-hidden="true"></i></button>
        </div>
        <template #content>
          <ul>
            <li><RouterLink to="/user">會員資料</RouterLink></li>
            <li><RouterLink to="/user/password">密碼管理</RouterLink></li>
            <li><a href="#" @click.prevent="logout">登出</a></li>
          </ul>
        </template>
      </Popper>
      <!-- 通知 -->
      <Popper class="dropdown" placement="bottom-start" arrow v-if="isLogin">
        <button class="header-nav-link notify-link is-dark is-icon">
          <i class="icon icon-bell" aria-hidden="true"></i>
          <span class="notify-badge" v-if="notifications.length > 0"></span>
        </button>
        <template #content class="dropdown">
          <div class="pl-1">通知 ({{ notifications.length }})</div>
          <ul class="notify-list">
            <li v-for="note in notifications" :key="note.numNo">
              <RouterLink :to="{ path: '/user/project', query: { id: note.numNo } }">
                <i class="icon icon-bell" aria-hidden="true"></i>
                <div>案件編號{{note.numNo}}，請於<mark>{{ $moment(note.expectedDate).format('L') }}</mark>前繳清{{ note.chargeName }}</div>
              </RouterLink>
            </li>
          </ul>
        </template>
      </Popper>
    </div>
    <nav
      ref="menu"
      class="menuMobile-wrapper"
      :style="[menuToggle ? { height: computedHeight } : {}]"
    >
      <div class="menuMobile-body">
        <ul class="menuMobile menu">
          <li class="menu-item">
            <RouterLink to="/user/project" class="menu-link">
              <i class="icon icon-menu-list" aria-hidden="true"></i>申請案件總表
            </RouterLink>
          </li>
          <li class="menu-item">
            <RouterLink to="/rent/concert" class="menu-link">
              <i class="icon icon-brand-concert" aria-hidden="true"></i>表演廳檔期申請
            </RouterLink>
          </li>
          <li class="menu-item">
            <RouterLink to="/rent/cultural" class="menu-link">
              <i class="icon icon-brand-cultural" aria-hidden="true"></i> 文化館檔期申請
            </RouterLink>
          </li>
          <li class="menu-item">
            <RouterLink to="/user/schedule" class="menu-link">
              <i class="icon icon-user-calendar" aria-hidden="true"></i>我的行事曆
            </RouterLink>
          </li>
          <li class="menu-item">
            <RouterLink to="/schedule" class="menu-link">
              <i class="icon icon-calendar" aria-hidden="true"></i>檔期行事曆
            </RouterLink>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useUserData, useUserNotification } from '@/store/user';

export default {
  components: {
  },
  data() {
    return {
      menuToggle: false,
      computedHeight: 0,
    };
  },
  methods: {
    initHeight() {
      const $el = this.$refs.menu;
      $el.style.height = 'auto';
      $el.style.position = 'absolute';
      $el.style.visibility = 'hidden';
      $el.style.display = 'block';

      const { height } = window.getComputedStyle($el);
      this.computedHeight = height;

      $el.style.position = null;
      $el.style.visibility = null;
      $el.style.display = null;
      $el.style.height = 0;
    },
    ...mapActions(useUserData, ['logout']),
    ...mapActions(useUserNotification, ['fetchUserNotification']),
  },
  computed: {
    ...mapState(useUserNotification, ['noteList', 'notifications']),
    ...mapState(useUserData, ['userData', 'userName', 'isLogin']),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.menuToggle = false;
      },
    },
  },
  mounted() {
    this.initHeight();
  },
  created() {
    if (this.isLogin) {
      this.fetchUserNotification();
    }
  },
};
</script>

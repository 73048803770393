<template>
  <div class="navbar">
    <ul class="breadcrumb">
      <!-- <li><RouterLink class="breadcrumb-link" to="/">首頁</RouterLink></li>
      <li>{{ currentPath.name }}</li> -->
    </ul>
    <button class="btn btn-light" @click="print"><i class="icon icon-print" aria-hidden="true"></i></button>
  </div>
</template>
<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
  computed: {
    currentPath() {
      return {
        name: this.$route.name,
        fullPath: this.$route.fullPath,
        path: this.$route.meta.path,
      };
    },
  },
};
</script>

<template>
  <div class="page-bg">
    <Header />
    <div class="container">
      <!-- 側邊選單 -->
      <Aside v-if="isLogin" />
      <!-- 內容區 -->
      <main class="content">
        <div class="wrap">
          <BreadCrumb />
          <div class="page-title">{{ pageTitle }}</div>
          <RouterView />
        </div>
      </main>
    </div>
    <!-- footer -->
    <footer class="footer">
      <div class="footer-wrapper">
        <div class="footer-left">
          <!-- 回官網 -->
          <a
            href="https://tmc.taipei/"
            target="_blank"
            title="回官網(另開視窗)"
            class="footer-logo"
          >
            <img src="@/assets/images/logo-white.svg" alt="" />
          </a>
        </div>
        <div class="footer-right">
          <p>Copyright © Taipei music center all rights reserved ｜ 臺北流行音樂中心 版權所有</p>
          <p>115028 台北市南港區市民大道8段99號 +886-02-2788-6620 info@tmc.taipei</p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useUserData, useUserEvents } from '@/store/user';
import { useFormStore } from '@/store/form';
import { useLoadingScreen } from '@/store/index';
import Header from '@/components/Header.vue';
import Aside from '@/components/Aside.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';

export default {
  components: {
    Header,
    Aside,
    BreadCrumb,
  },
  methods: {
    ...mapActions(useLoadingScreen, ['setLoading']),
    ...mapActions(useFormStore, ['initForm']),
    ...mapActions(useUserData, ['fetchUserData']),
    ...mapActions(useUserEvents, ['fetchUserEvents']),
  },
  computed: {
    pageTitle() {
      return this.$route.name;
    },
    ...mapState(useUserData, ['isLogin']),
  },
  watch: {
    pageTitle(title) {
      if (title === '申請案件總表') this.fetchUserEvents();
    },
  },
  async created() {
    // 登入後的資料預載(表單選項)
    if (this.isLogin) {
      this.setLoading(true);
      await Promise.all([this.fetchUserData(), this.initForm(), this.fetchUserEvents()]);
      this.setLoading(false);
    }
  },
};
</script>

<template>
  <aside class="aside">
    <ul class="asideList">
      <li class="asideList-item">
        <RouterLink to="/user/project" class="asideList-link">
          <i class="icon icon-menu-list" aria-hidden="true"></i>申請案件總表
        </RouterLink>
      </li>
      <li class="asideList-item">
        <RouterLink to="/rent/concert" class="asideList-link">
          <i class="icon icon-brand-concert" aria-hidden="true"></i>表演廳檔期申請
        </RouterLink>
      </li>
      <li class="asideList-item">
        <RouterLink to="/rent/cultural" class="asideList-link">
          <i class="icon icon-brand-cultural" aria-hidden="true"></i> 文化館檔期申請
        </RouterLink>
      </li>
      <li class="asideList-item">
        <RouterLink to="/user/schedule" class="asideList-link">
          <i class="icon icon-user-calendar" aria-hidden="true"></i>我的行事曆
        </RouterLink>
      </li>
    </ul>
  </aside>
</template>
<script>
export default {

};
</script>
